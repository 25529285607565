.hero-section{
    background-image: linear-gradient(to right, rgba(32, 32, 32, 0.68), rgba(191, 188, 188, 0.24)), url('../../assets/images/hero.jpg');
    /* background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/images/hero.jpg'); */
    /* background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3)), url('../../assets/images/hero.jpg'); */
    /* background-image: linear-gradient(to right,  #202020ae, #bfbcbc3e), url('../../assets/images/hero.jpg'); */
    /* background-image: url('../../assets/images/hero.jpg'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)); Adjust transparency as needed */
    /* background-image: linear-gradient(to right, rgba(0, 128, 128, 0.2), rgba(19, 18, 17, 0.5)), url('../../assets/images/hero.jpg'); */

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
}