.navbar {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}
.navbar .logo {
  display: flex;
}
/* .navbar .logo img {
  width: 190px;
  height: 60px;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease-in-out;
} */

.social-media {
  display: flex;
  gap: 15px;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow);
  /* padding: var(--primary-padding); */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: scale 0.4s ease-in-out;
}

.social-icon:hover {
  scale: 1.01;
}
.left-nav {
  display: flex;
  align-items: center;
}

.social-icon img {
  width: 20px;
  height: 20px;
}

.nav-divider {
  width: 1px;
  height: 50px;
  background-color: rgb(211, 209, 209);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-links {
  display: flex;
  align-items: center;
}
.nav-list {
  display: flex;
  gap: 25px;
}

.dropdown i {
  margin-left: 10px;
}
.dropdown-item {
  white-space: nowrap;
}
.donate-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 3rem;
}

.dotnet-btn {
  background-color: var(--primary-color);
  padding: var(--primary-padding) 25px;
  border-radius: var(--primary-radius);
  border: var(--primary-border);
  transition: var(--primary-transition);
}
.dotnet-btn:hover {
  background-color: transparent;
  border: var(--secondary-border);
}

.dotnet-btn-text {
  text-transform: uppercase;
  font-weight: bold;
}

.from-left {
  display: none;
}

.toggle-btn{
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  /* Styles for small screens (phones) */
  .nav-links {
    display: flex;
  }
  .nav-list {
    padding: 40px;
    flex-direction: column;
    position: absolute;
    inset-inline-start: 0px;
    background-color: inherit !important;
    top: 80px;
    width: 100%;
    height: inherit;
    transform: translateX(-100%);
    /* height: 0;
    overflow: hidden; */
  }
  .open-menu {
    transform: translateX(0%);
  }
  .from-left {
    display: flex;
    align-items: start !important;
    flex-direction: column-reverse;
    width: 100%;
    margin-left: 0;
  }
  .from-right {
    display: none;
  }
  .toggle-btn{
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  /* St1yles for medium screens (tablets) */
  .toggle-btn{
    display: block;
  }
  .nav-links {
    display: flex;
  }
  .nav-list {
    padding: 40px;
    flex-direction: column;
    position: absolute;
    inset-inline-start: 0px;
    background-color: inherit !important;
    top: 80px;
    transform: translateX(-100%);
  }
  .open-menu {
    transform: translateX(0%);
  }
}

@media screen and (min-width: 992px) {
  /* Styles for large screens (desktops) */
}
